.NavBarTop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
  padding: 0;

  z-index: 1000;
  background: var(--background);
}
.nav-top-push {
  height: 90px;
}
.NavBarTop .navbar-brand img {
  height: 80px;
}
.NavBarTop p {
  color: #414141;
  font-size: 16px;
  margin: 0;
}

.NavBarTop .menu {
  display: flex;
  flex-direction: row;
  font-weight: 500;
}
.NavBarTop .menu > span {
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #414141;
  transition: 0.2s;
  border-bottom: 2px solid transparent;
}
.NavBarTop .menu i {
  color: var(--main);
  font-size: 20px;
  margin-right: 4px;
  transition: 0.2s;
  opacity: 1;
}
.NavBarTop .menu > span:hover {
  color: var(--main);
  cursor: pointer;
  transition: 0.2s;
  border-bottom: 2px solid var(--main);
}
.NavBarTop .menu > span:hover i {
  opacity: 1;
  transition: 0.2s;
}
.NavBarTop a {
  color: inherit;
  text-decoration: none;
}

nav.top {
  .Links {
    display: flex;
    align-items: center;
    color: var(--blue);
    font-size: 14px;
    > * {
      margin-left: 24px;
    }
    a.PhoneLink {
      background: var(--blue);
      padding: 12px 24px;
      color: var(--blue_) !important;
      border-radius: 0;
      i {
        color: var(--blue_) !important;
      }
    }
  }
}

@media (min-width: 992px) {
}
@media (max-width: 768px) {
  nav.top .menu {
    flex-direction: column;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 90px;
    padding: 32px;
  }
  nav.top {
    .Links {
      font-size: 16px;
    }
  }
  nav.top .menu a {
    color: #fff;
    font-size: 24px !important;
  }
  nav.top .menu a i {
    color: #fff;
    font-size: 24px !important;
    width: 26px;
  }
}
