body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* --main: #01bb39; */
  --main: #4dad26;
  --main_: #ffffff;
  --background: #ffffff;
  --backgroundSub: #eeeeee;
  --blue: #3c75a7;
  --blue_: #ffffff;
  --red: #ff3d00;
  --red_: #ffffff;
  --yellow: #f2e003;
  --yellow_: #000000;

  --whatsappTeelGreen: #075e54;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  color: var(--blue);
  text-decoration: none;
}
h1 {
  color: var(--main);
  font-weight: 800;
  font-size: 64px;
  margin-bottom: 32px;
}
h2 {
  color: var(--main);
  font-weight: 900;
  font-size: 64px;
  margin-bottom: 12px;
}
h1,
h2,
.subHeading,
h3,
h4,
h5,
h6 {
  font-family: "Manrope", sans-serif;
}
.subHeading {
  font-size: 28px;
  font-weight: 700;
  color: #111;
  font-family: "Manrope", sans-serif;
}

h3 {
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 22px;
}

li,
p {
  font-size: 20px;
  color: #414141;
}

@media (max-width: 768px) {
  h1 {
    font-size: 44px;
  }
  h2 {
    font-size: 44px;
  }
  h3 {
    font-size: 20px;
  }
  .subHeading {
    font-size: 22px;
    font-weight: 700;
    color: #111;
    font-family: "Manrope", sans-serif;
  }
  li,
  p {
    font-size: 18px;
    color: #414141;
  }
}

input,
textarea {
  outline: none;
  border: none;
  padding: 12px 16px;
  margin-bottom: 8px;
  background: var(--backgroundSub);
  border-radius: 0;
  font-weight: 600;
  color: #000;
}
body,
html {
  overflow-x: hidden;
  max-width: 100vw;
}

.box-1 ul {
  list-style: none;
}

.box-1 ul li:before {
  content: "\2022";
  color: var(--main);
  transform: scale(1.5) translateY(-1.5px);
  font-weight: 700;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.box-1 ul li {
  font-size: 20px;
}

button {
  border: none;
  background: var(--main);
  color: #fff;
  font-size: 22px;
  padding: 8px 12px;
  border-radius: 0;
}

button i {
  margin-left: 20px;
}

i.big {
  font-size: 330px;
  color: var(--main);
  text-align: center;
  margin-top: 32px;
}

.box-2 {
  padding-top: 32px;
  margin-top: 64px;
  background: var(--backgroundSub);
}

.box-2 .images .item {
  text-align: center;
  padding: 0;
  color: #fff;
  background-size: cover;
  background-position: 50%;
}

.box-2 .images .item .inner {
  background: var(--blurBlue);
  height: 100%;
  width: 100%;
  min-height: 260px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  transition: 0.2s;
  cursor: pointer;
  padding: 16px;
}

.box-2 .images .item .inner:hover {
  background: var(--blurBlueSecond);
  transition: 0.4s;
}

.box-3 {
  padding-bottom: 132px;
}

.box-3 .places {
  background: var(--main);
  color: #fff;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  border-radius: 0;
}

.box-3 .places .place {
  flex: 1 1;
  text-align: center;
  padding: 12px;
  transition: 0.5s;
  cursor: pointer;
}

.box-3 .places .place:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
  transition: 0.2s;
}

.box-3 .places .place.active {
  background-color: hsla(0, 0%, 100%, 0.2);
  transition: 0s;
}

.box-3 .contatData {
  background: var(--backgroundSub);
  margin: 8px 0;
  border-radius: 0;
}
.box-3 .contatData button {
  margin-bottom: 12px;
}

footer {
  background: var(--backgroundSub);
  padding: 32px 16px;
}

footer a {
  display: block;
  text-decoration: none;
  font-size: 20px;
}

footer a i {
  margin-right: 4px;
}

footer .copyright a {
  font-size: 14px;
}

footer .space-text {
  margin: 0 6px;
}

iframe {
  border-radius: 0;
}

header {
  position: relative;
  background-color: #000;
  height: 70vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}

header .container {
  position: relative;
  z-index: 2;
}

header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--main);
  opacity: 0.2;
  z-index: 1;
}

header .sloagen {
  background-color: var(--main);
  display: block;
  color: #fff;
  padding: 4px 12px;
  font-size: 32px;
}

.NavBarTop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
}

.nav-top-push {
  height: 62px;
}

.NavBarTop .navbar-brand {
  font-weight: 800;
  color: var(--main);
  font-size: 24px;
}

.NavBarTop p {
  color: #414141;
  font-size: 16px;
  margin: 0;
}

.NavBarTop .menu {
  display: flex;
  flex-direction: row;
  font-weight: 500;
}

.NavBarTop .menu > span {
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #414141;
  transition: 0.2s;
  border-bottom: 2px solid transparent;
}

.NavBarTop .menu i {
  color: var(--main);
  font-size: 20px;
  margin-right: 4px;
  transition: 0.2s;
  opacity: 1;
}

.NavBarTop .menu > span:hover {
  color: var(--main);
  cursor: pointer;
  transition: 0.2s;
  border-bottom: 2px solid var(--main);
}

.NavBarTop .menu > span:hover i {
  opacity: 1;
  transition: 0.2s;
}

.NavBarTop a {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 768px) {
  nav.top .menu {
    flex-direction: column;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 62px;
    padding: 32px;
  }
  nav.top .menu a,
  nav.top .menu a i {
    color: #fff;
    font-size: 24px !important;
  }
  nav.top .menu a i {
    width: 26px;
  }
}

.TextPage {
  padding: 32px 16px;
  min-height: calc(100vh - 72px);
}

.TextPage > .container {
  padding: 0;
}

.TextPage h1.big {
  font-size: 52px;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.TextPage h1 i {
  font-size: 52px;
  padding-right: 14px;
}

.TextPage p {
  color: #414141;
}

@media (max-width: 991.98px) {
  .TextPage h1.big {
    font-size: 28px;
    font-weight: 700;
  }
  .TextPage h1 i {
    font-size: 32px;
  }
}

/*# sourceMappingURL=main.d60c9635.chunk.css.map */
