.VideoHeader2 {
  max-height: 50vh;
  overflow: hidden;
}

.VideoHeader {
  padding-top: 0 !important;

  header {
    position: relative;
    background-color: black;
    overflow: hidden;

    .ratio {
      .inner {
        position: absolute;
        display: flex;
        justify-content: end;
        align-items: flex-end;
      }
    }

    .cont {
      position: relative;
      z-index: 2;
    }
  }

  header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    // background-color: var(--main);
    z-index: 1;
  }

  @media (pointer: coarse) and (hover: none) {
    header {
      /* background: url('https://source.unsplash.com/XT5OInaElMw/1600x900') black no-repeat center center scroll; */
    }
    header video {
      /* display: none; */
    }
  }

  header .slogan-box {
    border-radius: 0;
    background-color: var(--main);
    padding: 12px 24px;
    font-weight: 500;
    .slogan {
      display: block;
      color: #fff;
      font-size: 32px;
      padding: 0;
      margin: 0;
    }
  }
}
