.HomePage {
  .box {
    .Fader {
      padding-top: 128px;
      background: transparent;
      svg {
        path {
          fill: var(--backgroundSub);
        }
      }
    }
  }

  .box:nth-of-type(2n + 1) {
    background: var(--backgroundSub);
    .Fader {
      svg {
        path {
          fill: var(--background);
        }
      }
    }
  }
}
