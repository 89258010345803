.FeaturesBox {
  .FeatureBox {
    padding: 16px !important;
    .card {
      background: var(--background);
      height: 100%;
      border-radius: 0;
      border: none;
      overflow: hidden;

      .imageBox {
        background-size: 105%;
        background-position: center;
        transition: 1s;
      }

      .heading {
        display: flex;
        align-items: center;
        height: 52px;
        background: var(--main);
        margin-bottom: -16px;
        z-index: 100;
        padding: 12px 18px;
        h3 {
          color: #fff;
          margin: 0;
        }
      }

      &:hover {
        .imageBox {
          background-size: 100%;
          transition: 0.4s;
        }
      }
    }
  }
}
