.TextPage {
  padding: 32px 16px;
  min-height: calc(100vh - 72px);
}
.TextPage > .container {
  padding: 0;
}
.TextPage h1.big {
  font-size: 52px;
  font-weight: 700;

  display: flex;
  align-items: center;

  cursor: pointer;
}
.TextPage h1 i {
  font-size: 52px;
  padding-right: 14px;
}
.TextPage p {
    color: #414141;
}


@media (max-width: 991.98px) { 

    
    .TextPage h1.big {
        font-size: 28px;
        font-weight: 700;
    }
    .TextPage h1 i {
        font-size: 32px;
    }
}