.ContactFormular {
  max-width: 700px;

  p {
    margin-bottom: 8px;
  }

  .inputBox {
    margin-bottom: 32px;

    input,
    textarea {
      &::placeholder {
        font-weight: 600;
        color: var(--blue);
      }
    }

    .textareaBox {
      background: var(--backgroundSub);
      border-radius: 12px;
      padding: 12px 16px;
      textarea {
        padding: 0;
        font-weight: 600;
      }
      span {
        font-weight: 400;
        color: #555;
        cursor: not-allowed;
      }
    }
    input,
    textarea {
      width: 100%;
    }
  }
}
