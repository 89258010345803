.BarBottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  background: var(--backgroundSub);
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);

  padding: 12px;
  text-align: center;

  .WhatsAppLink {
    font-size: 20px;
  }
}
